import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import axios from 'axios';

const PhotoUpload = ({ onImagesUpload }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append('photos[]', file);
      });

      // Send the files to the server
      const response=await axios.post('https://bareillyconnect.com/bareilly/v4/product/uploadImages.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
       
      console.log("photo uplkoad response ",response);
         // Extract the list of uploaded files from the response
         const { files } = response.data;

         // Update state in the child component
         setUploadedImages(files);
   
         // Call the callback function provided by the parent component
         onImagesUpload(files);

      // Optionally, reset the selected files
      setSelectedFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <div>
      <Input
        type="file"
        inputProps={{ multiple: true }}
        onChange={handleFileChange}
      />

      {selectedFiles.length > 0 && (
        <div>
          <h2>Selected Files:</h2>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {selectedFiles.map((file, index) => (
              <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index + 1}`}
                  style={{ width: '50px', height: '50px', marginRight: '8px' }}
                />
                <span>{file.name}</span>
                <Button onClick={() => handleRemove(index)} color="secondary" size="small" style={{ marginLeft: '8px' }}>
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
    </div>
  );
};

export default PhotoUpload;
